<template>
  <el-card>
    <div class="ml20">
      <el-row class="mb20">
        <el-col :span="6">名称：{{ formData.facilityName || '' }}</el-col>
        <el-col :span="6">编号：{{ formData.facilityNo || '' }}</el-col>
      </el-row>
      <el-row class="mb20">
        <el-col :span="6">类型：{{ formData.facilityTypeName || '' }}</el-col>
        <el-col :span="6">所属项目：{{ formData.projectName || '' }}</el-col>
      </el-row>
      <el-row class="mb20">
        <el-col :span="6">位置描述：{{ formData.address || '' }}</el-col>
        <el-col :span="6">位置定位：
          <el-button @click="isShow = true" type="primary">查看定位</el-button>
        </el-col>
      </el-row>
      <el-row type="flex">
        <span style="line-height: 100px;">图片：</span>
        <el-image
          style="width: 100px; height: 100px"
          v-if="formData.fileList && formData.fileList.length > 0"
          :src="formData.fileList && formData.fileList[0].filePrefix + formData.fileList[0].fileUrl"
          :preview-src-list="[formData.fileList && formData.fileList[0].filePrefix + formData.fileList[0].fileUrl]"></el-image>
      </el-row>
    </div>
    <el-dialog
      title="查看位置"
      :visible="isShow"
      width="500px"
      :modal-append-to-body="false"
      @close="isShow = false">
      <map-location
        class="mapContent"
        :isChange="false"
        :projectId="formData.projectId"
        :locationList="[{ longitude: formData.longitude, latitude: formData.latitude }]"
        :icon="require('@/assets/img/location.png')"
      ></map-location>
    </el-dialog>
  </el-card>
</template>

<script>
import MapLocation from '@/components/mapLocation/Index.vue'
export default {
  name: 'ElementDetail',
  components: { MapLocation },
  data () {
    return {
      isShow: false,
      formData: {}
    }
  },
  computed: {
    facilityId () {
      return this.$route.params.id || ''
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    // 查看设备设施详情
    loadData () {
      this.$axios.get(this.$apis.patrol.selectFacilityByFacilityId, {
        facilityId: this.facilityId
      }).then(res => {
        this.formData = res || {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #dddddd;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px  0  20px;
    .el-dialog__title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ml20 {
    width: auto;
    height: auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
  }
</style>
